<tc-loader *ngIf="trainings.isLoading() || seasons.isLoading()" />
<div
  class="flex flex-col lg:flex-row gap-4 p-4 min-h-full max-w-full w-full"
  *ngIf="!trainings.isLoading() && !seasons.isLoading()"
>
  <div
    class="flex flex-col md:flex-row lg:min-w-1/5 lg:flex-col gap-4"
    *ngIf="(trainings.data() && trainings.data()!.length > 0) || (seasons.data() && seasons.data()!.length > 0)"
  >
    <tc-filter-facet
      type="single"
      [selected]="seasonFilters()"
      (selectedChange)="onSetSeasons($event)"
      [options]="seasonOptions()"
      [default]="activeSeasons()"
      header="Season"
      class="w-full"
    />
    <tc-filter-facet
      type="multiple"
      [selected]="otherFilters()"
      (selectedChange)="otherFilters.set($event)"
      [options]="filterOptionsOther()"
      [default]="[filterOptionsOther()[0].key]"
      header="Other"
      class="w-full"
    />
    <tc-filter-facet
      type="multiple"
      [selected]="formatFilters()"
      (selectedChange)="formatFilters.set($event)"
      [options]="formats()"
      [showFormatLegenda]="true"
      header="Format"
      class="w-full"
      *ngIf="formats().length > 0"
    />
    <tc-filter-facet
      type="multiple"
      [selected]="domainFilters()"
      (selectedChange)="domainFilters.set($event)"
      [options]="domains()"
      header="Domain"
      class="w-full"
      *ngIf="domains().length > 0"
    />
  </div>
  <div class="w-full">
    <p-card>
      <p *ngIf="seasons.data()?.length === 0; else showSelectButton">No seasons found</p>
      <ng-template #showSelectButton>
        <p-selectButton
          [ngModel]="view()"
          (onChange)="toggleCalendar()"
          [options]="viewOptions"
          optionLabel="label"
          optionValue="value"
          [allowEmpty]="false"
          styleClass="pb-2"
        />
      </ng-template>

      <tc-training-calendar-year
        *ngIf="view() === 'calendar'"
        [trainings]="filteredTrainings()"
        [bookings]="filteredBookings()"
        [publicHolidays]="filteredPublicHolidays()"
        [schoolHolidays]="filteredSchoolHolidays()"
        [season]="currentSeason()"
        (getIcal)="onGetIcal($event)"
      />
      <tc-training-calendar-timeline
        *ngIf="view() === 'timeline'"
        [bookings]="filteredBookings()"
        [trainings]="filteredTrainings()"
        [season]="currentSeason()"
      />
    </p-card>
  </div>
</div>
